import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export default function SidbarDashboard() {
    const location = useLocation();
    const { t } = useTranslation();

    // للتحكم في فتح وإغلاق الـ collapse
    const [isOpen, setIsOpen] = useState(false);

    // لتغيير حالة الـ collapse
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className='bg-sidbar vh-100 overflow-auto mx-0'>
                <div className='mx-2 mt-2 overflow-auto '>
                    <Link to={"/slider"} className={location.pathname === "/slider" ? "text-decoration-none mx-4 itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>slider</span>
                    </Link>
                    <Link to={"/services"} className={location.pathname === "/services" ? "text-decoration-none itemsidbar mx-3 mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("services")}</span>
                    </Link>
                    <Link to={"/Doctors"} className={location.pathname === "/Doctors" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("doctors")}</span>
                    </Link>
                    <Link to={"/Admin"} className={location.pathname === "/Admin" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("admin")}</span>
                    </Link>
                    <Link to={"/Gallery"} className={location.pathname === "/Gallery" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("gallery")}</span>
                    </Link>
                    <Link to={"/Reviews"} className={location.pathname === "/Reviews" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("reviews")}</span>
                    </Link>
                    <Link to={"/faq"} className={location.pathname === "/faq" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("faq")}</span>
                    </Link>
                    <Link to={"/ContactUS"} className={location.pathname === "/ContactUS" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("ContactUS")}</span>
                    </Link>

                    {/* Accordion Section بدون Bootstrap */}
                    <div className="accordion bg-sidbar ">
                        <div className="accordion-item bg-sidbar">
                            <h2 className="accordion-header bg-sidbar">
                                <button
                                    className="accordion-button bg-sidbar"
                                    type="button"
                                    onClick={toggleCollapse}
                                >
                                    {t("staticPages")}
                                </button>
                            </h2>
                            <div className={`accordion-collapse ${isOpen ? 'open' : ''}`}>
                                <div className="accordion-body">
                                    <Link to={"/about"} className={location.pathname === "/about" ? "text-decoration-none itemsidbar  p-3 bg-color" : "text-decoration-none itemsidbar  p-3"}>
                                        {t("about")}
                                    </Link>
                                    <Link to={"/Features"} className={location.pathname === "/Features" ? "text-decoration-none itemsidbar   p-3 bg-color" : "text-decoration-none itemsidbar   p-3"}>
                                        {t("Features")}
                                    </Link>
                                    <Link to={"/Content"} className={location.pathname === "/Content" ? "text-decoration-none itemsidbar   p-3 bg-color" : "text-decoration-none itemsidbar   p-3"}>
                                        {t("Content")}
                                    </Link>
                                    <Link to={"/AboutHeader"} className={location.pathname === "/AboutHeader" ? "text-decoration-none itemsidbar   p-3 bg-color" : "text-decoration-none itemsidbar  p-3"}>
                                        {t("Header")}
                                    </Link>
                                    <Link to={"/Imgillness"} className={location.pathname === "/Imgillness" ? "text-decoration-none itemsidbar   p-3 bg-color" : "text-decoration-none itemsidbar  p-3"}>
                                        {t("Imgillness")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Link to={"/Setting"} className={location.pathname === "/Setting" ? "text-decoration-none itemsidbar mx-3  p-3 bg-color" : "text-decoration-none itemsidbar mx-3  p-3"}>
                        <span className='text-end p-0 m-0 text-decoration-none'>{t("setting")}</span>
                    </Link>

                </div>
            </div>
        </>
    );
}
