// src/Components/Routes/routes.js

import { createBrowserRouter } from 'react-router-dom';
import Layout from '../Layout/Layout';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import HomeDashboard from '../Dashboard/Home';
import Login from '../Auth/Login';
import NotFound from '../NotFound/NotFound';
import LoginDashboard from "../Dashboard/Auth/Login/Login";
import MyDataTable from '../DataTable/DataTable';
import Slider from '../Dashboard/Slider/Slider';
import About from '../Dashboard/About/About';
import Profile from '../Dashboard/Profile/Profile';
import Doctors from '../Dashboard/Doctors/Doctors';
import Admin from '../Dashboard/Admin/Admin';
import Gallery from '../Dashboard/Gallery/Gallery';
import Setting from '../Dashboard/Setting/Setting';
import Faq from '../Dashboard/FAQ/Faq';
import ContactUS from '../Dashboard/Contact-US/ContactUS';
import Reviews from '../Reviews/Reviews';
import Aboutservoce from '../About/About';
import Features from '../Features/Features';
import Content from '../content/content';
import AboutHeader from '../AboutHeader/AboutHeader';
import Imgillness from '../Imgillness/Imgillness';

const routers = createBrowserRouter([
  // {  
  //   path: "", 
  //   element: <Layout/>,  // General layout for non-dashboard pages
  //   children: [
  //     { path: "Home", element: <Home/> },
  //     { path: "Login", element: <Login/> },
  //     { path: "MyDataTable", element: <MyDataTable/> },
  //     { path: "*", element: <NotFound/> }
  //   ] 
  // },
  { 
    path: "", 
    element: <Dashboard/>,  // Dashboard layout for dashboard pages
    children: [
      { path: "", element: <HomeDashboard/> }, 
      { path: "homeDashboard", element: <HomeDashboard/> },
      { path: "slider", element: <Slider/> },
      { path: "services", element: <About/> },
      { path: "LoginDashboard", element: <LoginDashboard/> },
      { path: "profile", element: <Profile/> },
      { path: "Doctors", element: <Doctors/> },
      { path: "Admin", element: <Admin/> },
      { path: "Gallery", element: <Gallery/> },
      { path: "Setting", element: <Setting/> },
      { path: "faq", element: <Faq/> },
      { path: "ContactUS", element: <ContactUS/> },
      { path: "Reviews", element: <Reviews/> },
      { path: "about", element: <Aboutservoce/> },
      { path: "Features", element: <Features/> },
      { path: "Content", element: <Content/> },
      { path: "AboutHeader", element: <AboutHeader/> },
      { path: "Imgillness", element: <Imgillness/> },
    ]
  }
]);
export default routers;