import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ColorRing } from 'react-loader-spinner';
import { UserContext } from '../../Context/Context';
import { useTranslation } from 'react-i18next';

export default function EditDoctor({ id }) {
  const [titleAr, setTitleAr] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionDe, setDescriptionDe] = useState('');
  const [specialityAr, setSpecialityAr] = useState('');
  const [specialityEn, setSpecialityEn] = useState('');
  const [specialityDe, setSpecialityDe] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { url, lan } = useContext(UserContext);
  const { t } = useTranslation();

  const getData = async () => {
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(`${url}/dashboard/doctors/${id}?lang=${lan}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.data;
  };

  const { data, isError, isLoading } = useQuery(["getDoctor", id], getData, {
    onSuccess: (data) => {
      setTitleAr(data.name || '');
      setTitleEn(data.email || '');
      setTitleDe(data.phone || '');
      setDescriptionAr(data.description_ar || '');
      setDescriptionEn(data.description_en || '');
      setDescriptionDe(data.description_de || '');
      setSpecialityAr(data.speciality_ar || '');
      setSpecialityEn(data.speciality_en || '');
      setSpecialityDe(data.speciality_de || '');
    },
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <div className='alert alert-danger'>Not  internet connection</div>;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("usertoken");
    const formData = new FormData();

    formData.append('name', titleAr);
    formData.append('email', titleEn);
    formData.append('phone', titleDe);
    formData.append('password', descriptionAr);
    formData.append('speciality_ar', specialityAr);
    formData.append('speciality_de', specialityDe);
    formData.append('speciality_en', specialityEn);
    formData.append('description_en', descriptionEn);
    formData.append('description_de', descriptionDe);
    if (image) formData.append('image', image);

    try {
      const response = await axios.post(`${url}/dashboard/doctors/${id}?lang=${lan}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        params: {
          '_method': 'put'
        }
      });

      if (response.data.status) {
        toast.success(response.data.message);
      }
      console.log('Data updated successfully:', response.data.status);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='mb-3 mt-3'>
          <label className='fw-bold'>   {t("name")} </label>
          <input
            type='text'
            className='form-control mt-2'
            value={titleAr}
            onChange={(e) => setTitleAr(e.target.value)}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'>  {t("email")}   </label>
          <input
            type='text'
            className='form-control mt-2'
            value={titleEn}
            onChange={(e) => setTitleEn(e.target.value)}
          />
        </div>




        <div className='mb-3 mt-3'>
          <label className='fw-bold'>   {t("speciality_ar")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={specialityAr}
            onChange={(e) => setSpecialityAr(e.target.value)}
          />
        </div>



        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("speciality_en")}   </label>
          <input
            type='text'
            className='form-control mt-2'
            value={specialityEn}
            onChange={(e) => setSpecialityEn(e.target.value)}
          />
        </div>



        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("speciality_de")}  </label>
          <input
            type='text'
            className='form-control mt-2'
            value={specialityDe}
            onChange={(e) => setSpecialityDe(e.target.value)}
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("phone")}  </label>
          <input
            type='number'
            className='form-control mt-2'
            value={titleDe}
            onChange={(e) => setTitleDe(e.target.value)}
          />
        </div>





        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("descriptioninarabic")}    </label>
          <textarea
            type='text'
            className='form-control mt-2'
            value={descriptionAr}
            onChange={(e) => setDescriptionAr(e.target.value)}
            rows={4} 
          />
        </div>




        <div className='mb-3 mt-3'>
  <label className='fw-bold'>     {t("descriptioninenglish")}     </label>
  <textarea
    className='form-control mt-2'
    value={descriptionEn}
    onChange={(e) => setDescriptionEn(e.target.value)}
    rows={4} // يمكنك تغيير عدد الأسطر حسب الحاجة
  />
</div>


        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("descriptioningerman")}   </label>
          <textarea
            type='text'
            className='form-control mt-2'
            value={descriptionDe}
            onChange={(e) => setDescriptionDe(e.target.value)}
            rows={4} 
          />
        </div>

        <div className='mb-3 mt-3'>
          <label className='fw-bold'> {t("image")} </label>
          <input
            type='file'
            className='form-control mt-2'
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>

        <div className='mt-3'>
          <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
            ) : (
              'إرسال'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
