import React, { useContext, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ColorRing } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';

export default function EditeAbout({ id }) {
    const [titleAr, setTitleAr] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleDe, setTitleDe] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [image, setImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    const { url, lan } = useContext(UserContext);

    // Handlers for inputs
    const handleTitleArChange = (e) => setTitleAr(e.target.value);
    const handleTitleEnChange = (e) => setTitleEn(e.target.value);
    const handleTitleDeChange = (e) => setTitleDe(e.target.value);
    const handleDescriptionArChange = (e) => setDescriptionAr(e.target.value);
    const handleDescriptionEnChange = (e) => setDescriptionEn(e.target.value);
    const handleDescriptionDeChange = (e) => setDescriptionDe(e.target.value);
    const handleImageChange = (e) => setImage(e.target.files[0]);

    // Function to fetch the current data
    const fetchAboutData = async () => {
        const token = localStorage.getItem('usertoken');
        const response = await axios.get(`${url}/dashboard/staticPage/${id}?lang=${lan}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    };

    // Using react-query to get the data
    const { data, isLoading, isError } = useQuery(['aboutData', id], fetchAboutData, {
        onSuccess: (data) => {
            setTitleAr(data.title_ar || '');
            setTitleEn(data.title_en || '');
            setTitleDe(data.title_de || '');
            setDescriptionAr(data.description_ar || '');
            setDescriptionEn(data.description_en || '');
            setDescriptionDe(data.description_de || '');
        },
    });

    // Function to handle the form submission
    const handleSubmit = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem('usertoken');
        const formData = new FormData();

        formData.append('title_ar', titleAr);
        formData.append('title_en', titleEn);
        formData.append('title_de', titleDe);
        formData.append('description_ar', descriptionAr);
        formData.append('description_en', descriptionEn);
        formData.append('description_de', descriptionDe);
        formData.append('_method', 'put');
        formData.append('key', 'About');
        if (image) formData.append('image', image);

        try {
            const response = await axios.post(`${url}/dashboard/staticPage/${id}?lang=${lan}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status === true) {
                toast.success(response.data.message);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error fetching data.</div>;

    return (
        <div className='container'>
            <div className='row'>
                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t('namearbic')} </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleAr}
                        onChange={handleTitleArChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t('nameenglesh')} </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleEn}
                        onChange={handleTitleEnChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t('namegerman')} </label>
                    <input
                        type='text'
                        className='form-control popupinput mt-2'
                        value={titleDe}
                        onChange={handleTitleDeChange}
                    />
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t('descriptioninarabic')} </label>
                    <textarea
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionAr}
                        onChange={handleDescriptionArChange}
                        style={{ height: '120px' }}
                    ></textarea>
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t('descriptioninenglish')} </label>
                    <textarea
                        type='text'
                        className='form-control popupinput mt-2'
                        value={descriptionEn}
                        onChange={handleDescriptionEnChange}
                        style={{ height: '120px' }}
                    ></textarea>
                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'> {t('descriptioningerman')} </label>
                    <textarea
    className='form-control popupinput mt-2'
    value={descriptionDe}
    onChange={handleDescriptionDeChange}
    cols="100"
    style={{ height: '120px' }}
    rows="20" // You can adjust the number of rows based on your preference
></textarea>

                </div>

                <div className='mb-3 mt-3'>
                    <label className='fw-bold'>{t('image')}</label>
                    <input
                        type='file'
                        className='form-control popupinput mt-2'
                        onChange={handleImageChange}
                    />
                </div>

                <div className='mt-3'>
                    <button className='btn bg-color' onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? (
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        ) : (
                            t('send')
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}
